<template>
  <div ref="bar" class="bar" :style="{width: `${barWidth}px`}"></div>
</template>

<script>
export default {
  name: 'barForReport',
  data () {
    return {
      barWidth: ''
    }
  },
  methods: {
    initCharts ({dataParam, rangeArr, width}) {
      if (width) this.barWidth = width
      this.$nextTick(() => {
        const echarts = require('echarts')
        let { myChart } = this
        if (!myChart) {
          myChart = echarts.init(this.$refs.bar)
          this.myChart = myChart
        } else {
          myChart.clear()
          myChart.resize()
        }
        const option = {
          tooltip: {},
          xAxis: {
            axisLine: {show: false},
            axisTick: {show: false},
            axisLabel: {
              show: true,
              color: '#666',
              emphasis: {
                color: '#fff'
              }
            }
          },
          yAxis: {
            show: true,
            max: 100
          },
          series: []
        }
        Object.assign(option, dataParam)
        myChart.setOption(option)
      })
    }
  }
}
</script>

<style scoped>
.bar {
  height: 260px;
}
</style>
