<template>
  <div>
    <section class="section">
      <div class="title">
        <div class="left">
          <div class="line"></div>
          <div>数据概览</div>
          <div class="icon"></div>
          <div class="tips">
            <span class="col-329cf3">{{className}}</span>共进行了
            <span class="col-329cf3">{{arrangeSum}}</span>次模拟考试。整体完成情况如下：</div>
        </div>
      </div>
      <div class="charts" ref="content">
        <div ref="main">
          <barForReport ref="bar"></barForReport>
        </div>
      </div>
    </section>

    <section class="section" v-if="classId">
      <div class="title">
        <div class="left">
          <div class="ml-28">得分率分布统计</div>
        </div>
      </div>
      <div class="wrapper">
        <!--<div class="tips">*点击蓝色柱状图可查看对应学生名单</div>-->
        <div class="chartsList" ref="wrapper">
          <barForReport ref="chartsList"></barForReport>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="title">
        <div class="left">
          <div class="ml-28">得分成长趋势</div>
        </div>
      </div>
      <div class="charts">
        <div class="radio">
          <el-radio-group v-model="scoreGrowthIndex" @change="scoreGrowthChange">
            <el-radio class="mr-14" :label="index" v-for="(item, index) in scoreGrowthList" :key="index">{{item.name}}</el-radio>
          </el-radio-group>
        </div>
        <div class="list" ref="lineWrapper">
          <LineCharts ref="line"></LineCharts>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import barForReport from './barForReport'
import LineCharts from './LineCharts'
export default {
  name: 'examCharts',
  components: {
    barForReport,
    LineCharts
  },
  props: ['className', 'classId', 'type', 'schoolId', 'gradeId', 'year'],
  data () {
    return {
      arrangeSum: '',
      scoreGrowthList: [],
      scoreGrowthIndex: 0
    }
  },
  watch: {
    classId () {
      this.getDataViewExam()
    },
    type () {
      this.getDataViewExam()
    }
  },
  methods: {
    getDataViewExam (url, dataParam) {
      this.loading = true
      const urls = url || this.$urls.getDataViewExam
      const dataParams = dataParam || {
        classId: this.classId,
        type: this.type
      }
      this.$axios({
        method: 'post',
        url: urls,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParams
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            this.arrangeSum = data.arrangeSum
            const { questionType, scoringGroup, scoreGrowth } = data
            const questionTypeObj = {
              name: '得分率',
              xNameList: [],
              xValueList: []
            }
            for (let i = 0, len = questionType.length; i < len; i += 1) {
              questionTypeObj.xNameList.push(questionType[i].questionTypeName)
              questionTypeObj.xValueList.push(questionType[i].scoreRate)
            }
            this.initMainCharts(questionTypeObj)
            if (this.classId) {
              const scoringGroupList = []
              for (let i = 0, len = scoringGroup.length; i < len; i += 1) {
                const obj = {
                  name: scoringGroup[i].questionTypeName,
                  xNameList: [],
                  xValueList: [],
                  studentSumList: [],
                  studentList: []
                }
                const { scoreRate } = scoringGroup[i]
                for (let j = 0, len = scoreRate.length; j < len; j += 1) {
                  obj.xNameList.push(scoreRate[j].columns)
                  obj.xValueList.push(Math.abs(scoreRate[j].proportion))
                  obj.studentSumList.push(scoreRate[j].studentSum)
                  obj.studentList.push(scoreRate[j].studentList)
                }
                scoringGroupList.push(obj)
              }
              this.$refs.wrapper.style.width = `${scoringGroupList.length * 500}px`
              this.initListCharts(scoringGroupList)
            }
            const scoreGrowthList = []
            for (let i = 0, len = scoreGrowth.length; i < len; i += 1) {
              const obj = {
                name: scoreGrowth[i].questionTypeName,
                xNameList: [],
                xValueList: [],
                studentSumList: [],
                studentList: []
              }
              const { examInfo } = scoreGrowth[i]
              for (let j = 0, len = examInfo.length; j < len; j += 1) {
                obj.xNameList.push(examInfo[j].examName)
                obj.xValueList.push(examInfo[j].scoreRate)
              }
              scoreGrowthList.push(obj)
            }
            this.scoreGrowthList = scoreGrowthList
            if (scoreGrowthList.length > 0) {
              this.questionType = scoreGrowthList[0]
            }
            this.initLineCharts(scoreGrowthList[0] || {})
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    initMainCharts (questionTypeObj) {
      const { offsetWidth } = this.$refs.content
      const listWidth = questionTypeObj.xNameList.length * 134
      let barWidth
      if (listWidth > offsetWidth) {
        barWidth = listWidth
      } else {
        barWidth = offsetWidth
      }
      this.$refs.main.style.width = `${barWidth}px`
      const echarts = require('echarts')
      const option = {
        grid: {
          left: '35',
          right: '0'
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: 'white'
          },
          formatter: function (params) {
            const res = `<p>${params.name}</p><p>平均得分率：${params.value}%</p>`
            return res
          }
        },
        xAxis: {
          axisLine: {show: false},
          axisTick: {show: false},
          axisLabel: {
            interval: 0,
            textStyle: {
              fontSize: '12',
              color: '#333'
            }
          },
          show: true,
          emphasis: {
            color: '#fff'
          },
          data: questionTypeObj.xNameList
        },
        yAxis: {
          show: true,
          name: '得分率(%)',
          min: 0,
          max: 100
        },
        series: [{
          name: '人数',
          type: 'bar',
          data: questionTypeObj.xValueList,
          barMaxWidth: 30,
          itemStyle: {
            color: '#309AF2',
            label: {
              show: true,
              formatter: '{c}人',
              position: 'top',
              textStyle: {
                color: 'black',
                fontSize: 16
              }
            }
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#6DBBFC'}, // 柱图高亮渐变色
                  {offset: 1, color: '#309AF2'} // 柱图高亮渐变色
                ]
              )
            }
          }
        }]
      }
      this.$refs.bar.initCharts({
        dataParam: option,
        width: barWidth
      })
    },
    initListCharts (list) {
      if (list.length === 0) {
        this.$refs.chartsList.initCharts({
          dataParam: {},
          width: 500
        })
        return
      }
      const barWidth = list.length * 500
      const echarts = require('echarts')
      const titleList = []
      const xAxis = []
      const yAxis = []
      const series = []
      const grid = []
      for (let i = 0, len = list.length; i < len; i += 1) {
        const obj = {
          text: list[i].name,
          textStyle: {
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 'bold'
          }
        }
        obj.left = (500 * i) + 25
        titleList.push(obj)
        const xAxisObj = {
          axisLine: {show: false},
          axisTick: {show: false},
          show: true,
          gridIndex: i,
          textStyle: {
            color: '#666'
          },
          emphasis: {
            color: '#fff'
          },
          data: list[i].xNameList
        }
        xAxis.push(xAxisObj)
        const yAxisObj = {
          show: true,
          gridIndex: i,
          name: '人数占比(%)',
          nameTextStyle: {
            color: '#999',
            padding: [0,0,0,15]
          },
          min: 0,
          max: 100
        }
        yAxis.push(yAxisObj)
        const seriesObj = {
          name: '人数',
          type: 'bar',
          xAxisIndex: i,
          yAxisIndex: i,
          data: list[i].xValueList,
          barWidth: 30,
          itemStyle: {
            color: '#309AF2',
            label: {
              show: true,
              formatter: '{c}人',
              position: 'top',
              textStyle: {
                color: 'black',
                fontSize: 16
              }
            }
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#6DBBFC'}, // 柱图高亮渐变色
                  {offset: 1, color: '#309AF2'} // 柱图高亮渐变色
                ]
              )
            }
          }
        }
        series.push(seriesObj)
        const gridObj = {width: '450', height: '60%'}
        gridObj.left = i ? ((500 * i) + 55) + '' : '55'
        grid.push(gridObj)
      }
      const option = {
        grid,
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: 'white'
          },
          formatter: function (params) {
            const listObj = list[params.componentIndex]
            const res = `<p>得分率：${params.name}</p><p>人数：${listObj.studentSumList[params.dataIndex]}</p><p>${listObj.studentList[params.dataIndex]}</p>`
            return res
          },
          extraCssText: 'max-width:400px;white-space: pre-wrap'
        },
        title: titleList,
        xAxis,
        yAxis,
        series
      }
      this.$refs.chartsList.initCharts({
        dataParam: option,
        width: barWidth
      })
    },
    initLineCharts (scoreGrowth) {
      const { offsetWidth } = this.$refs.content
      const listWidth = scoreGrowth.xNameList.length * 134
      let barWidth
      if (listWidth > offsetWidth) {
        barWidth = listWidth
      } else {
        barWidth = offsetWidth
      }
      this.$refs.lineWrapper.style.width = `${barWidth}px`
      const echarts = require('echarts')
      const option = {
        grid: {
          left: '35',
          right: '0'
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: 'white'
          },
          formatter: function (params) {
            const res = `<p>${scoreGrowth.xNameList[params.dataIndex]}</p><p>平均得分率：${scoreGrowth.xValueList[params.dataIndex]}%</p>`
            return res
          }
        },
        xAxis: {
          show: false,
          type: 'category',
          data: scoreGrowth.xNameList
        },
        yAxis: {
          name: '得分率(%)',
          max: 100,
          min: 0
        },
        series: [{
          data: scoreGrowth.xValueList,
          type: 'line',
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(48, 154, 242, .1)'
            }, {
              offset: 1,
              color: 'rgba(48, 154, 242, 0)'
            }])
          }
        }]
      }
      this.$refs.line.initCharts({
        dataParam: option,
        width: barWidth
      })
    },
    scoreGrowthChange () {
      const { scoreGrowthList, scoreGrowthIndex } = this
      this.initLineCharts(scoreGrowthList[scoreGrowthIndex])
    },
  }
}
</script>

<style scoped lang="scss">
  .section {
    background-color: #fff;
    margin-bottom: 10px;
    overflow: hidden;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 66px;
      padding-right: 28px;
      @include font;
      font-weight: bold;
      &:hover {
        .hidden {
          visibility: visible;
        }
      }
      .left {
        display: flex;
        align-items: center;
      }
      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
      .icon {
        background-image: url("../../../assets/icon-2.png");
        background-size: 100% 100%;
        width: 10px;
        height: 10px;
        margin-left: 20px;
      }
      .tips {
        background-color: #EAF4FD;
        height: 34px;
        line-height: 34px;
        padding: 0 20px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: normal;
      }
      .right {
        display: flex;
        align-items: center;
        color: #309AF2;
        font-size: 14px;
      }
      .btn {
        background: #EAF4FD url("../../../assets/z-1.png") no-repeat center center;
        background-size: 11px 6px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 10px;
        cursor: pointer;
        transition: all .3s;
      }
      .loading-btn {
        background: #EAF4FD url("../../../assets/loading.png") no-repeat center center;
        background-size: 16px 16px;
        animation: rotate .5s linear infinite;
      }
    }
    .charts {
      margin: 0 28px 20px;
      border: 1px solid #E5E5E5;
      overflow: auto;
      .radio {
        padding: 12px 14px 0;
      }
    }
    .wrapper {
      overflow: auto;
      margin: 0 28px 28px;
      .chartsList {
        height: 280px;
        padding-top: 18px;
        border: 1px solid #E5E5E5;

        .bar{
          left: -10px;
        }
      }
    }
    .sub {
      border-top: 2px solid #1DC060;
      .sub-title {
        display: inline-block;
        background: linear-gradient(to right, #1DC060, #3EE199);
        height: 28px;
        line-height: 28px;
        margin-left: 28px;
        padding: 0 14px;
        border-radius: 0 0 8px 8px;
        color: #fff;
        font-size: 14px;
        .mark {
          width: 14px;
          height: 14px;
          margin-left: 6px;
          vertical-align: middle;
        }
      }
      .sub-title-default {
        background: linear-gradient(to right, #309AF2, #5ECAFA);
      }
      .sub-title-warn {
        background: linear-gradient(to right, #FF6F5E, #FF6F5E);
      }
    }
    .sub-2 {
      border-top: 2px solid #309AF2;
      .sub-title {
        background: linear-gradient(to right, #309AF2, #5ECAFA);
      }
    }
    .sub-3 {
      border-top: 2px solid #FF3C30;
      .sub-title {
        background: linear-gradient(to right, #FF3C30, #FF6F5E);
      }
    }
    .table {
      padding: 0 28px 20px;
      .link {
        color: #309AF2;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .more-btn {
      @include flex;
      background-color: #F6F6F6;
      width: 120px;
      height: 32px;
      margin: 0 auto 40px;
      border-radius: 20px;
      color: #309AF2;
      font-size: 14px;
      cursor: pointer;
      img {
        width: 7px;
        height: 4px;
        margin-left: 4px;
        transition: all .3s;
      }
    }
  }
  .ml-28 {
    margin-left: 28px;
  }
</style>
