<template>
  <div ref="charts" class="line"></div>
</template>

<script>
export default {
  name: 'LineCharts',
  data () {
    return {
      barWidth: ''
    }
  },
  methods: {
    initCharts ({dataParam, rangeArr, width}) {
      if (width) {
        this.$refs.charts.style.width = `${width}px`
      }
      this.$nextTick(() => {
        const echarts = require('echarts')
        let { myChart } = this
        if (!myChart) {
          myChart = echarts.init(this.$refs.charts)
          this.myChart = myChart
        } else {
          myChart.clear()
          myChart.resize()
        }
        const option = {
          tooltip: {},
          yAxis: {
            type: 'value',
            max: 100,
            min: 0
          }
        }
        Object.assign(option, dataParam)
        myChart.setOption(option)
      })
    }
  }
}
</script>

<style scoped>
  .line {
    height: 260px;
  }
</style>
